import React from "react"
import { useTabbedContainer } from "contexts/components/TabbedContainerContext"
import { Tabs } from "components/tabs/Tabs"
import { useColorAreaSettings } from "hooks/useColorAreaSettings"
import { useWindowDimensions } from "hooks/useWindowDimensions"
import { TabbedHeroContentSlider } from "./TabbedHeroContentSlider"
import "./tabbed-hero.module.scss"
import { HeroTab } from "./hero-tab-types"

interface TabbedHeroBannerContentProps {
  contentClassName: string
  handleGetHeroBgImageUrl: (
    activeTabContent?: HeroTab
  ) => Record<string, string>
  handleActiveTabContent: (activeTabContent: HeroTab) => JSX.Element
}

export const TabbedHeroBannerContent = ({
  contentClassName,
  handleGetHeroBgImageUrl,
  handleActiveTabContent,
}: TabbedHeroBannerContentProps) => {
  const { size } = useWindowDimensions()
  const { activeItem } = useTabbedContainer<HeroTab>()
  const { imageBannerColorOverlay } = useColorAreaSettings()
  const overrideBackground = imageBannerColorOverlay
    ? `${imageBannerColorOverlay}--backgroundOverlayBefore`
    : ""

  return (
    <section
      className={`fr-hero ${overrideBackground}`}
      style={handleGetHeroBgImageUrl(activeItem)}
    >
      <div className={`${contentClassName} fr-container fr-container--large`}>
        {size >= 769 && (
          <>
            <Tabs className="fr-hero-tabs-controller" />
            {activeItem && handleActiveTabContent(activeItem)}
          </>
        )}
        {size > 0 && size <= 768 && <TabbedHeroContentSlider />}
      </div>
    </section>
  )
}
