import React, { useState } from "react"
import Slider from "react-slick"
import { SampleNextArrow, SamplePrevArrow } from "./SliderArrow"
import { HeroTab } from "./hero-tab-types"
import { HeroContent } from "../HeroContent"
import { useTabbedContainer } from "contexts/components/TabbedContainerContext"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./tabbed-hero-content-slider.module.scss"

interface TabbedHeroContentSliderProps {
  hasAltBackgroundColor?: boolean
}

export const TabbedHeroContentSlider = ({
  hasAltBackgroundColor = false,
}: TabbedHeroContentSliderProps) => {
  const [nav1, setNav1] = useState<Slider>()
  const [nav2, setNav2] = useState<Slider>()
  const { allContent } = useTabbedContainer<HeroTab>()

  const tabSettings = {
    dots: true,
    customPaging: function (i: number) {
      return (
        <button key={i} className="fr-tabbed-hero-content-slider__tab">
          {i}
        </button>
      )
    },
    dotsClass: "slick-dots fr-tabbed-hero-content-slider__slick-dots",
    className: "fr-tabbed-hero-content-slider__tabs",
    infinite: true,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  const contentSettings = {
    dots: false,
    arrows: false,
    dotsClass: "slick-dots fr-tabbed-hero-content-slider__slick-dots",
    className: "fr-tabbed-hero-content-slider__content",
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
  }

  return (
    <>
      <div className="fr-tabbed-hero-content-slider__tabs-wrapper">
        <Slider
          asNavFor={nav2}
          {...tabSettings}
          ref={slider1 => slider1 && setNav1(slider1)}
        >
          {allContent.map(({ elements, id }) => {
            if (!elements) return
            const { tab_title } = elements
            return (
              <div key={id}>
                <div className="fr-tabbed-hero-content-slider__single-tab">
                  <strong>{tab_title?.value}</strong>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
      <Slider
        asNavFor={nav1}
        {...contentSettings}
        ref={slider2 => slider2 && setNav2(slider2)}
      >
        {allContent.map(({ elements, id }) => {
          if (!elements) return null

          const { heading, buttons, sub_heading } = elements

          return (
            <HeroContent
              key={id}
              heading={heading?.value}
              subHeading={sub_heading}
              buttons={buttons?.value}
              baseClassName="hero-banner-content"
            />
          )
        })}
      </Slider>
    </>
  )
}
