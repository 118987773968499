import React, { useCallback } from "react"
import { graphql } from "gatsby"
import { getBackgroundImageUrlByBreakpoint } from "assets/helper/getBackgroundImageUrlByBreakpoint"
import { HeroContent } from "../HeroContent"
import { CmsAssetImage } from "_types/AssetsImage"
import { TabbedHeroBannerContent } from "./TabbedHeroBannerContent"
import { HeroTab } from "./hero-tab-types"
import { TabbedContainer } from "components/tabs/TabbedContainer"
import "../hero.module.scss"
import { HighlightsGroup } from "components/highlights/highlights-types"
import { useWindowDimensions } from "hooks/useWindowDimensions"
import { HeroHighlights } from "components/highlights/Hero/HeroHighlights"

interface TabbedHeroBannerProps {
  banner: {
    background_image?: CmsAssetImage
    secondary_image?: CmsAssetImage
    hero_tabs: {
      modular_content: HeroTab[]
    }
    default_components?: {
      modular_content: HighlightsGroup[]
    }
  }
}

export const TabbedHeroBanner = ({ banner }: TabbedHeroBannerProps) => {
  const { size } = useWindowDimensions()

  const { secondary_image, hero_tabs, default_components } = banner

  const getActiveTabContent = useCallback(
    (activeTabContent: HeroTab) => {
      const { heading, sub_heading, buttons, components_override } =
        activeTabContent?.elements

      const components =
        components_override && components_override?.modular_content?.length > 0
          ? components_override?.modular_content
          : default_components?.modular_content

      const highlights = components?.filter(
        component => component?.system?.type === "highlights_group"
      )[0]?.elements?.highlights?.modular_content

      return (
        <HeroContent
          heading={heading?.value}
          subHeading={sub_heading}
          buttons={buttons?.value}
          baseClassName="hero-banner-content"
        >
          {size >= 768 && highlights && highlights.length > 0 && (
            <div className={`hero-banner-content__highlights`}>
              <HeroHighlights highlights={highlights} />
            </div>
          )}
        </HeroContent>
      )
    },
    [default_components, size]
  )

  const handleGetHeroBgImageUrl = useCallback(
    (activeTabContent?: HeroTab) => {
      if (!activeTabContent) return {}
      const heroBackgroundImageUrl =
        activeTabContent?.elements?.secondary_image_override?.value[0]?.url ||
        secondary_image?.value[0].url

      const backgroundImageCSSVariable = heroBackgroundImageUrl
        ? getBackgroundImageUrlByBreakpoint({
            variableName: "hero-background-image",
            imageUrl: heroBackgroundImageUrl,
            breakpoints: {
              mobile: {
                width: 768,
              },
            },
          })
        : {}

      return backgroundImageCSSVariable
    },
    [secondary_image]
  )

  return (
    <TabbedContainer allContent={hero_tabs.modular_content}>
      <TabbedHeroBannerContent
        contentClassName="fr-tabbed-hero-banner"
        handleGetHeroBgImageUrl={handleGetHeroBgImageUrl}
        handleActiveTabContent={getActiveTabContent}
      />
    </TabbedContainer>
  )
}

export const tabbedHeroFragment = graphql`
  fragment TabbedHeroBannerFragment on kontent_item_tabbed_hero {
    id
    elements {
      hero_tabs {
        modular_content {
          ...HeroTabFragment
        }
      }
      default_components {
        modular_content {
          ...HighlightGroupFragment
        }
      }
      background_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      secondary_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
    }
  }
`

export const heroTabFragment = graphql`
  fragment HeroTabFragment on kontent_item_hero_tab {
    id
    elements {
      tab_title {
        value
      }
      heading {
        value
      }
      sub_heading {
        value
        links {
          link_id
          url_slug
        }
      }
      tagline {
        value
      }
      components_override {
        modular_content {
          ...HighlightGroupFragment
        }
      }
      background_image_override {
        value {
          ...KenticoAssetElementFragment
        }
      }
      secondary_image_override {
        value {
          ...KenticoAssetElementFragment
        }
      }
      buttons {
        value {
          ...ButtonFragment
          ...AnchorLinkFragment
        }
      }
    }
  }
`
