import React from "react"
import { useInView } from "react-intersection-observer"
import { SingleHighlight } from "../highlights-types"
import "./hero-highlights.module.scss"
import { Highlight } from "../Highlight"

interface HeroHighlightsProps {
  highlights: SingleHighlight[]
}

export const HeroHighlights = ({ highlights }: HeroHighlightsProps) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  })
  return (
    <div className={`hero-highlights__wrapper`} ref={ref}>
      {highlights.map(({ elements, id }) => {
        const {
          heading,
          caption,
          svg_icon,
          image,
          counter_start_value,
          counter_end_value,
          animated_counter,
          special_character,
        } = elements

        return (
          <Highlight
            baseClassName="hero-highlights"
            title={heading?.value}
            image={image?.value[0]}
            caption={caption?.value}
            key={id}
            rightSvg={svg_icon?.value}
            counterStartValue={counter_start_value?.value}
            counterEndValue={counter_end_value?.value}
            inView={inView}
            isAnimated={animated_counter?.value[0]?.codename === "yes"}
            specialCharacter={special_character?.value[0]?.name}
          />
        )
      })}
    </div>
  )
}
